import { React, useEffect, useState } from 'react';
import { getFirestore, setDoc, doc, updateDoc, getDoc } from 'firebase/firestore';
import axios from 'axios';
import '../App.css';



function ProcessFacebookRedirect(props) {
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    //handleFacebookRedirect();
    const urlParams = new URLSearchParams(window.location.search);
    const code = urlParams.get('code');
    console.log(code);
    if (code) {
      saveCodeToDB(code)
    }
  }, []);


  async function saveCodeToDB (code) {
    const currentUrl = window.location.href;
    const baseUrl = currentUrl.split('?')[0];
    var redirectUrl = currentUrl.split('/')[0] + '//' + currentUrl.split('/')[1] + currentUrl.split('/')[2];

    const uid = localStorage.getItem('uid');
    console.log(uid);
    console.log(code)

    // Save the code to Firestore
    const firestore = getFirestore();
    const userRef = doc(firestore, 'users', uid);

    // Check if the document exists
    const docSnapshot = await getDoc(userRef);

    if (docSnapshot.exists()) {
      // Update the existing document with the new code value
      updateDoc(userRef, { facebookOAuthCode: code, facebookOAuthURL: baseUrl })
        .then(() => {
          console.log('Code updated in Firestore successfully');
          createAccessToken()
          //window.location = redirectUrl;
        })
        .catch((error) => {
          console.error('Error updating code in Firestore:', error);
        });
    } else {
      // Create a new document with the code value
      setDoc(userRef, { facebookOAuthCode: code, facebookOAuthURL: baseUrl })
        .then(() => {
          console.log('Code saved to Firestore successfully');
          createAccessToken()
         // window.location = redirectUrl;
        })
        .catch((error) => {
          console.error('Error saving code to Firestore:', error);
        });
    }


    async function createAccessToken() {
      const accessTokenEndpoint = 'https://getfacebookaccesstoken-rqrp3h5a5a-uc.a.run.app?uid=' + uid;

      axios.get(accessTokenEndpoint)
      .then(function (response) {
        // Handle successful response
        console.log('Response Data:', response.data);
        setLoading(false)
        if (response.data == 'error') {
          setError(true)
        }
      })
      .catch(function (error) {
        // Handle error
        console.error('Error:', error);
        setError(true)
        setLoading(false)
      });
    }


  }



     

    
    return ( 
      <div className='container'>
        <div className='loginContainer'>
          <div className='loginSubContainer'>
            {loading ?
              <span className=''>Loading</span>
            :
              <div>
                {error ?
                  <div>
                    <h2 style={{textAlign: "center"}}>Oops!</h2>
                    <h4 style={{textAlign: "center"}}>Looks like there was an error. Please try again.</h4>  
                  </div>
                :
                  <div>
                    <h2 style={{textAlign: "center"}}>You're all set up!</h2>
                    <h4 style={{textAlign: "center"}}>Now continue on your iOS or Android app</h4>  
                  </div>
                }
                
              </div>
            }
          </div>
        </div>
      </div>
     );
}

export default ProcessFacebookRedirect;