// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { GoogleAuthProvider } from "firebase/auth";


// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyDkBuuNvxo_MUS9nhOzICfF9rQ27lFRjN8",
  authDomain: "omni-media-197a0.firebaseapp.com",
  databaseURL: "https://omni-media-197a0-default-rtdb.firebaseio.com",
  projectId: "omni-media-197a0",
  storageBucket: "omni-media-197a0.appspot.com",
  messagingSenderId: "663765932973",
  appId: "1:663765932973:web:aa9ac344224182537edf24",
  measurementId: "G-SP845HZ6G1"
};
  

const firebaseApp = initializeApp(firebaseConfig);
const db = getFirestore();

const provider = new GoogleAuthProvider();



export {db}; 