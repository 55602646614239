import '../App.css';
import { getAuth, createUserWithEmailAndPassword } from "firebase/auth";
import react, { useState } from 'react';
import { collection, doc, setDoc } from 'firebase/firestore';
import {db} from '../FirebaseInit';


function SignUp() {
    const [step, setStep] = useState(0);

    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
  
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
  
  
  
    async function signUp () {
      const auth = getAuth();
      createUserWithEmailAndPassword(auth, email, password)
        .then((userCredential) => {
          // Signed in
          setStep(1)
          const user = userCredential.user;
          const userId = user.uid;
          console.log("User Created Successfully => UID: " + userId)
          localStorage.setItem('uid', userId);
          // Create a reference to the Firestore collection where you want to store the user data
          const usersCollection = collection(db, 'users');
          // Set the additional user data in Firestore
          setDoc(doc(usersCollection, userId), {
            firstName: '',
            lastName: '',
            userID: userId,
            createdAt: new Date(),
            email: email,
            type: 'emailAuth',
            facebookOAuthCode: '',
            facebookOAuthURL: '',
            facebookOAuthAccessToken: '',
            facebookOAuthAccessTokenCreatedAt: '',
            lastUsedFacebookAdAccount: {},
            lastUsedFacebookPixel: {},
            lastUsedFacebookPage: {},
            shopifyAdminAccessToken: '',
            shopifyAdminAPIKey: '',
            shopifyAdminAPISecretKey: '',
            // Add any other properties you want to store
          });
  
          // ...
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          console.log(errorMessage)
          // ..
        });
    }
  
  
    async function updateName () {
      const auth = getAuth();
      const user = auth.currentUser;
  
      if (user) {
        const userId = user.uid;
        
        try {
          // Create a reference to the Firestore document for the user
          const userDocRef = doc(db, 'users', userId);
  
          // Update the user's first name and last name
          await setDoc(userDocRef, {
            firstName: firstName,
            lastName: lastName,
          }, { merge: true }); // Use merge option to update only the specified fields
          setStep(2)
          console.log('User name updated successfully');
        } catch (error) {
          console.error('Error updating user name:', error);
        }
      } else {
        console.error('User not authenticated'); // Handle the case where the user is not authenticated
      }
    }
  
  
    async function linkFacebook () {
      try {
        const currentUrl = window.location.href;
        const baseUrl = currentUrl.split('/')[0] + '//' + currentUrl.split('/')[1] + currentUrl.split('/')[2] + '/process_facebook_redirect';
        const redirectUri = encodeURIComponent(baseUrl);
        // Redirect the user to the Facebook login page
        const redirectUrl = `https://www.facebook.com/v13.0/dialog/oauth?client_id=209614795354373&redirect_uri=${redirectUri}&config_id=627785086071805&response_type=code&scope=ads_read,ads_management,business_management`;
        window.location.href = redirectUrl;
      } catch (error) {
        // Handle any errors
        console.error('Error logging in with Facebook:', error);
      }
    }
  
  
  
    return (
      <div className="container">
        <div className='loginContainer'>
          {step == 0 ?
            <div className='loginSubContainer'>
              <span className='welcomeText'>Welcome to Omni!</span>
              <span className='welcomeSubText'>Create an account</span>
              <input type='email' placeholder='Email' value={email} onChange={(event) => setEmail(event.target.value)}/>
              <input type='password' placeholder='Password' value={password} onChange={(event) => setPassword(event.target.value)}/>
              <button className='buttonContainer' onClick={() => {signUp()}}>Continue</button>
            </div>
          : 
            <div></div>
          }
  
          {step == 1 ?
            <div className='loginSubContainer'>
              <span className='welcomeText'>Welcome to Omni!</span>
              <span className='welcomeSubText'>Add your account details</span>
              <input type='text' placeholder='First Name' value={firstName} onChange={(event) => setFirstName(event.target.value)}/>
              <input type='text' placeholder='Last Name' value={lastName} onChange={(event) => setLastName(event.target.value)}/>
              <button className='buttonContainer' onClick={() => {updateName()}}>Continue</button>
            </div>
          : 
            <div></div>
          }
  
          {step == 2 ?
            <div className='loginSubContainer'>
              <span className='welcomeText'>Welcome to Omni!</span>
              <span className='welcomeSubText'>Link your Facebook</span>
              <button className='facebookButtonContainer' onClick={() => {linkFacebook()}}>Connect Facebook</button>
            </div>
          : 
            <div></div>
          }
  
  
        </div>
      </div>
    );
}

export default SignUp;