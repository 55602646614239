
import { BrowserRouter, Route, Routes, Navigate, NavLink } from 'react-router-dom';
import SignUp from './pages/SignUp';
import ProcessFacebookRedirect from './pages/ProcessFacebookRedirect';


function App() {
  return (
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<SignUp />} />
      <Route path="/process_facebook_redirect" element={<ProcessFacebookRedirect />} />
    </Routes>
  </BrowserRouter>
  )
}

export default App;
